import React from 'react';
import { Container, Table } from 'react-bootstrap';

function Info() {
    return (
        <Container>
            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td>Phone</td>
                        <td> +47 922 54 767</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>mruben93@gmail.com</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>Sannergata 32A, 0557 Oslo</td>
                    </tr>
                    <tr>
                        <td>My Phone</td>
                        <td>+47 412 68 752</td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
}

export default Info;
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BlogPostCard from '../components/posts/BlogPostCard'
import { Stack, Pagination } from 'react-bootstrap'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from "react-router-dom";
import useBlogPostService from '../services/blogPostService';
import './posts.css'
import Spinner from 'react-bootstrap/Spinner';

const Posts = () => {
    const { user } = useAuth0();
    const [blogViews, setBlogViews] = useState([]);
    const navigate = useNavigate();
    const { fetchBlogPosts } = useBlogPostService();
    const { type } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchAndSetBlogViews = async (type) => {
            try {
                const { posts, totalCount } = await fetchBlogPosts(type, currentPage);
                setBlogViews(posts);
                setTotalPages(Math.ceil(totalCount / 10));
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };
        fetchAndSetBlogViews(type);
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    const userHasAdminRole = () => {
        return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
    };

    return (
        <div className='mainContainer'>
            <Row >
                <Col xs={9} xl={9} className='postContainer'>
                    {blogViews.length > 0 ?
                        (<Stack direction="vertical" gap={3}>
                            {blogViews.map((view) => (
                                <BlogPostCard key={view.id} post={view} />
                            ))}
                        </Stack>
                        ) :
                        <Spinner animation="grow" variant="secondary" />

                    }
                </Col>
                <Col xs={3} xl={3}>
                    <div className="vertical-line"></div>
                </Col>
            </Row>

            {totalPages > 1 && (
                <div className="createButtonContainer">
                    <Pagination>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <Pagination.Item
                                key={page}
                                active={page === currentPage}
                                onClick={() => handlePageChange(page)}
                            >
                                {page}
                            </Pagination.Item>
                        ))}
                        </Pagination>
                </div>
            )}

            <div className='createButtonContainer'>{
                userHasAdminRole() &&
                <Button variant="dark" onClick={() => navigate("/posts/create")}>Create</Button>
            }
            </div>

        </div>
    );
};

export default Posts;
import { useAuth0 } from '@auth0/auth0-react';

const useBlogPostService = () => {
    const { getAccessTokenSilently } = useAuth0();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchBlogPosts = async (type, page = 1, pageSize = 10) => {
        try {
            let accessToken;
            var typeQuery = type ? `type=${type}&` : "";
            try {
                accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        scope: "read:current_user"
                    },
                });
            } catch (error) {
                // If the user is not authenticated, accessToken will be undefined
                console.log('User is not authenticated:', error);
            }
            const response = await fetch(`${baseUrl}/api/BlogPosts/views?${typeQuery}page=${page}&size=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            const posts = await response.json();
            return posts;
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        }
    };

    const createBlogPost = async (blogPost) => {
        let accessToken = await getAccessTokenSilently({
            authorizationParams: {
                scope: "read:current_user"
            },
        });
        const response = await fetch(`${baseUrl}/api/BlogPosts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(blogPost),
        });
        return response;
    }

    const deleteBlogPost = async (postId) => {
        let accessToken;
        try {
            accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    scope: "read:current_user",
                },
            });
        } catch (error) {
            console.log('User is not authenticated:', error);
        }
        await fetch(`${baseUrl}/api/BlogPosts/${postId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
    }

    const getBlogPostById = async (postId) => {
        try {
            let accessToken;
            try {
                accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        scope: "read:current_user"
                    },
                });
            } catch (error) {
                // If the user is not authenticated, accessToken will be undefined
                console.log('User is not authenticated:', error);
            }
            const response = await fetch(`${baseUrl}/api/BlogPosts/${postId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            const post = await response.json();
            return post;
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        }
    }

    const updateBlogPost = async (postid, blogPost) => {
        let accessToken = await getAccessTokenSilently({
            authorizationParams: {
                scope: "read:current_user"
            },
        });
        const response = await fetch(`${baseUrl}/api/BlogPosts/${postid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(blogPost),
        });
        return response;
    }

    const handlePublicationStatusChange = async (id, status) => {
        try {
            let accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    scope: "read:current_user"
                },
            });
            const response = await fetch(`${baseUrl}/api/blogposts/publish/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(status)
            });

            if (!response.ok) {
                throw new Error('Failed to update publication status');
            }

            const result = await response.json();
            console.log('Publication status updated:', result);
        } catch (error) {
            console.error('Error updating publication status:', error);
        }
    };

    return { createBlogPost, fetchBlogPosts, getBlogPostById, updateBlogPost, deleteBlogPost, handlePublicationStatusChange };
}

export default useBlogPostService;

import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const PrivatePage = () => {
  return (
    <div>Private</div>
  );
};

export default withAuthenticationRequired(PrivatePage, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <div>Redirecting you to the login page...</div>
  )
});
import logo from '../images/logo.jpg';

function Header() {

    return (
        <div>
        <a href='/'>
            <img src={logo} alt="logo" className="rounded-lg logo-image" />
        </a>
            <p className="title">Գլյուկը ճամփեքին</p>
            <div className="head-line"></div>
        </div>
    );
}

export default Header;

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import LoginButton from '../components/LoginButton';
import LogoutButton from '../components/LogoutButton';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Login() {
  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const { userId } = useParams();
  const [userMetadata, setUserMetadata] = useState(null);
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const [userEmail, setUserEmail] =  useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const userDetailsByIdUrl = `${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        if(user["https://gluck.blog/user_metadata"]){
          setUserEmail( user["https://gluck.blog/user_metadata"]["email"]);
        }
        setUserMetadata(user_metadata);
      } catch (e) {
        console.error("Error fetching user metadata:", e);
      }
    };
    if (isAuthenticated && user?.sub) {
      getUserMetadata();
    }
  }, [getAccessTokenSilently, user?.sub, isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }


  return (
    <div>
      {isAuthenticated ?
        <Container>
          <Row>
            <Col xl={1}>
              <LogoutButton />
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={user.picture} alt={user.name} />
            </Col>
            <Col>
              <h2>{userEmail}</h2>
            </Col>
            <Col>
              <p>{user.email}</p>
            </Col>
            <Col>
              <p>id: {userId}</p>
            </Col>
            <Col>
              {userMetadata ? (
                <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
              ) : (
                "No user metadata defined"
              )}</Col>
          </Row>
        </Container>
        :
        <LoginButton />
      }
    </div>
  );
};

export default Login;